/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates strings
import classnames from "classnames";
// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";

function IndexNavbar(){
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 299 ||
        document.body.scrollTop > 299
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 300 ||
        document.body.scrollTop < 300
      ) {
        setNavbarColor("navbar-transparent");
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <Navbar className={classnames("fixed-top", navbarColor)} expand="lg">
      <Container>
        <div className="navbar-translate" >
          <NavbarBrand
            data-placement="bottom"
            href="/"
            target="_blank"
            title="Coded by N.Debsky"
          >
           <img
          alt="..."
          className=""
          src={require("assets/img/icon.png")}
          style={{width:'50px',height:'50px'}}
        />
          </NavbarBrand>
          <button
            aria-expanded={navbarCollapse}
            className={classnames("navbar-toggler navbar-toggler", {
              toggled: navbarCollapse,
            })}
            onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className="justify-content-end"
          navbar
          isOpen={navbarCollapse}>
          <Nav navbar>
         
          <NavItem style={{textAlign:'right',marginRight:10}}>
              <NavLink
              href="https://www.instagram.com/koubar_tr/"
              >
            <i className="fa fa-instagram" /> 
       
              </NavLink>
            </NavItem>
            <NavItem style={{textAlign:'right',marginRight:10}}>
              <NavLink
                  href="https://twitter.com/narjeskoubartr"
              >
           
            <i className="fa fa-twitter" />
            
              </NavLink>
            </NavItem>
            <NavItem style={{textAlign:'right',marginRight:10}}>
              <NavLink
              href="https://www.facebook.com/Koubar2020"
              >
           
            <i className="fa fa-facebook-square" /> 
              </NavLink>
            </NavItem>
            <NavItem style={{textAlign:'right',marginRight:10}}>
              <NavLink
              href="https://www.youtube.com/channel/UCBSJE5HPBau-W7LS3zQhWFw/discussion"
              >
           
            <i className="fa fa-youtube" /> 
              </NavLink>
            </NavItem>
            <NavItem style={{textAlign:'right',marginRight:10}}>
              <NavLink
                data-placement="bottom"
                // href="https://www.facebook.com/CreativeTim?ref=creativetim"
                target="_blank"
                style={{cursor: 'pointer'}}
                title="خدمات التي تقدمها البرمجيات الدقيقة"
               onClick={()=>{let offset = 150;
                window.scrollTo({
                behavior: "smooth",
                top:
                document.getElementById("#s").getBoundingClientRect().top -
                document.body.getBoundingClientRect().top -
                offset
            });}}
              >
                <p style={{marginRight:10,fontSize:16}}>تواصل معنا</p>
              </NavLink>
            </NavItem>




            <NavItem style={{textAlign:'right',marginRight:10}}>
              <NavLink
                data-placement="bottom"
                // href="https://www.facebook.com/CreativeTim?ref=creativetim"
                target="_blank"
                style={{cursor: 'pointer'}}
                title="خدمات التي تقدمها البرمجيات الدقيقة"
               onClick={()=>{let offset = 150;
                window.scrollTo({
                behavior: "smooth",
                top:
                document.getElementById("#team").getBoundingClientRect().top -
                document.body.getBoundingClientRect().top -
                offset
            });}}
              >
                <p style={{marginRight:10,fontSize:16}}>فريقنا</p>
              </NavLink>
            </NavItem>

            <NavItem style={{textAlign:'right',marginRight:10}}>
              <NavLink
                 style={{cursor: 'pointer'}}
                data-placement="bottom"
                // href="https://www.instagram.com/CreativeTimOfficial?ref=creativetim"
                onClick={()=>{
                  let offset = 30;
                  window.scrollTo({
                  behavior: "smooth",
                  top:
                  document.getElementById("#as").getBoundingClientRect().top -
                  document.body.getBoundingClientRect().top -
                  offset
              });
                  // 
                }}
                target="_blank"
                title="معلومات عن شركة البرمجيات الدقيقة">
                    <p  style={{marginRight:10,fontSize:16}}>خدماتنا</p>
                    {/* <i className="fa fa-instagram" /> */}
              </NavLink>
            </NavItem>


            <NavItem style={{textAlign:'right',marginRight:10}}>
              <NavLink
                 style={{cursor: 'pointer'}}
                data-placement="bottom"
                // href="https://www.instagram.com/CreativeTimOfficial?ref=creativetim"
                onClick={()=>{
                  let offset = 30;
                  window.scrollTo({
                  behavior: "smooth",
                  top:
                  document.getElementById("#who").getBoundingClientRect().top -
                  document.body.getBoundingClientRect().top -
                  offset
              });
                  // 
                }}
                target="_blank"
                title="معلومات عن شركة البرمجيات الدقيقة">
                    <p  style={{marginRight:10,fontSize:16}}>من نحن</p>
                    {/* <i className="fa fa-instagram" /> */}
              </NavLink>
            </NavItem>

           
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default IndexNavbar;
