/*!

=========================================================
* Paper Kit React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create switch buttons


// reactstrap components
import {
  Button,
  Input,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardFooter,Form,
  
} from "reactstrap";
class Example extends React.Component{
  constructor(){
    super();
    this.myRef = React.createRef()

  }
state={
  Nory:'خدماتنا'
}
componentDidMount(){}


mainbussiness(){
  try {
    return(
      <Card className="section mt-3" style={{backgroundColor:'#FFFF',padding:10,transform:'none'}} id="#as">
      <CardHeader style={{backgroundColor:'#FFFF',textAlign:"center" }}>
      <h2 style={{color:"#0295E9",fontWeight:'bold'}}>
    خدماتنا الرئيسية
       </h2>
          </CardHeader>
      <CardBody>
      <Row className="mr-2 ml-2 mt-2 mb-2" style={{textAlign:'right',direction:'rtl',justifyContent:'center'}}>
       <Col sm="6"  md='3' className="mt-3">  
       <Card className="mt-4 "  >
         <CardHeader className="m-0 p-0" style={{width:'100%',height:200}}>
        <img
          alt="..."
          className="img-rounded img-responsive"
          src={require("assets/img/nory.jpeg")}
          style={{width:"100%",height:'100%'}}
        /></CardHeader>
        <CardBody className="m-0 p-0">
        <div className="img-details">
          {/* <div className="author">
            <img
              alt="..."
              className="img-circle img-no-padding img-responsive"
              src={require("assets/img/faces/joe-gardner-2.jpg")}
            />
          </div>*/}
          <div className="m-3">
          <h3 className="pt-2 pb-2" style={{color:"#0295E9",fontWeight:'bolder'}}>لغات الترجمة </h3>
          <p style={{fontSize:18}}>نوفر حاليًّا خدمات الترجمة من و إلى تسع لغات مختلفة (الانجليزية/ الفرنسية/ الايطالية/ الإسبانية/ البرتغالية/ التركية/ الروسية/ الألمانية/ العربية) كما نقدّم خدمات ترجمة لغات أخرى عند الطلب.</p>
          </div>
        </div>
        </CardBody>
        </Card>
      </Col>
      <Col sm="6"  md='3' className="mt-3">  
       <Card className="mt-4" >
       <CardHeader className="m-0 p-0" style={{width:'100%',height:200}}>
        <img
          alt="..."
          className="img-rounded img-responsive"
          src={require("assets/img/files.png")}
          style={{width:"100%",height:'100%'}}
        />
        </CardHeader>
        <CardBody className="m-0 p-0">
        <div className="img-details">
          {/* <div className="author">
            <img
              alt="..."
              className="img-circle img-no-padding img-responsive"
              src={require("assets/img/faces/joe-gardner-2.jpg")}
            />
          </div>*/}
           <div className="m-3">
          <h3 className="pt-2 pb-3" style={{color:"#0295E9",fontWeight:'bolder'}}>ترجمة المستندات</h3>
          <p style={{fontSize:18}}>نقدّم خدمات الترجمة لجميع أنواع المستندات القانونيّة مثل الشهادات على مختلف أنواعها، التقارير الطبيّة، العقود، و الأبحاث العلميّة والمستندات الخاصّة.</p>
          </div>
        </div>
        </CardBody>
        </Card>
      </Col>
      <Col sm="6"  md='3' className="mt-3">  
       <Card className="mt-4" >
       <CardHeader className="m-0 p-0" style={{width:'100%',height:200}}>
        <img
          alt="..."
          className="img-rounded img-responsive"
          src={require("assets/img/aktam.png")}
          style={{width:"100%",height:"100%"}}
        /></CardHeader>
        <CardBody className="m-0 p-0">
        <div className="img-details">
          <div className="m-3">
          <h3 className="pt-2 pb-3" style={{color:"#0295E9",fontWeight:'bolder'}}>الأختام القانونية </h3>
          <p style={{fontSize:18}}> نوفر فرصة الحصول على الأختام من الهيئة القضائية والوزارة الخارجية عند الطلب.</p>
          </div>
        </div>
        </CardBody>
        </Card>
      </Col>

      <Col sm="6"  md='3' className="mt-3">  
       <Card className="mt-4"  >
         <CardHeader className="m-0 p-0" style={{width:'100%',height:200}}>
        <img
          alt="..."
          className="img-rounded img-responsive"
          src={require("assets/img/lipsenc.jpeg")}
          style={{width:"100%",height:'100%'}}
        /></CardHeader>
        <CardBody className="m-0 p-0">
        <div className="img-details">
          {/* <div className="author">
            <img
              alt="..."
              className="img-circle img-no-padding img-responsive"
              src={require("assets/img/faces/joe-gardner-2.jpg")}
            />
          </div>*/}
          <div className="m-3">
          <h3 className="pt-2 pb-2" style={{color:"#0295E9",fontWeight:'bolder'}}>الترجمة الشفوية</h3>
          <p style={{fontSize:18}}>نوفر لعملائنا خدمات الترجمة الشفوية الفورية سواء بالحضور الشخصي او عبر منصات التواصل الإلكتروني.</p>
          </div>
        </div>
        </CardBody>
        </Card>
      </Col>
      <Col sm="6"  md='3' className="mt-3">  
       <Card className="mt-4" >
       <CardHeader className="m-0 p-0" style={{width:'100%',height:200}}>
        <img
          alt="..."
          className="img-rounded img-responsive"
          src={require("assets/img/payment.jpeg")}
          style={{width:"100%",height:'100%'}}
        />
        </CardHeader>
        <CardBody className="m-0 p-0">
        <div className="img-details">
          {/* <div className="author">
            <img
              alt="..."
              className="img-circle img-no-padding img-responsive"
              src={require("assets/img/faces/joe-gardner-2.jpg")}
            />
          </div>*/}
           <div className="m-3">
          <h3 className="pt-2 pb-3" style={{color:"#0295E9",fontWeight:'bolder'}}>طرق الدفع</h3>
          <p style={{fontSize:18}}>نوفر لكم طرق دفع مختلفة مثل  خدمة سداد، الصكوك المصرفية والدفع النقدي.</p>
          </div>
        </div>
        </CardBody>
        </Card>
      </Col>
      <Col sm="6"  md='3' className="mt-3">  
       <Card className="mt-4" >
       <CardHeader className="m-0 p-0" style={{width:'100%',height:200}}>
        <img
          alt="..."
          className="img-rounded img-responsive"
          src={require("assets/img/Taxi.jpg")}
          style={{width:"100%",height:"100%"}}
        /></CardHeader>
        <CardBody className="m-0 p-0">
        <div className="img-details">
          <div className="m-3">
          <h3 className="pt-2 pb-3" style={{color:"#0295E9",fontWeight:'bolder'}}>خدمات التوصيل</h3>
          <p style={{fontSize:18}}>نوفر خدمات التوصيل المجاني لطلبات الترجمة التي يفوق عددها  العشر صفحات .</p>
          </div>
        </div>
        </CardBody>
        </Card>
      </Col>
      </Row>
      </CardBody>
      </Card>);
  } catch (error) {
   
  }
}

OureTeam(){
  try {
    return(
      <Card className="section m-3" style={{backgroundColor:'#FFFF',padding:10,transform:'none'}} >
      <CardHeader style={{backgroundColor:'#FFFF',textAlign:"center" }} id="#team">
      <h2 style={{color:"#0295E9",fontWeight:'bold'}}>
     فريقنا 
       </h2>
          </CardHeader>
      <CardBody>

      <Row className="mr-0 ml-0 mt-2 mb-2" style={{textAlign:'right',direction:'rtl',justifyContent:'center'}}>

      <Col sm="6"  md='3'>  
 <Card className="mt-4" >
   <CardHeader className="m-0 p-0" style={{backgroundColor:'#FFFF'}}>
     <div style={{marginRight:'25%',width:'41%'}}>
  <img
    alt="..."
    className="img-responsive ml-auto mr-auto"
    src={require("assets/img/narjess.jpeg")}
    style={{borderRadius:"50%"}}
  />
  </div>
  </CardHeader>
  <CardBody className="m-0 p-0">
  <div className="img-details">
    <div className="m-3">
    <h3 className="pt-2 pb-2" style={{color:"#0295E9",fontWeight:'bolder'}}>نرجس مصطفى</h3>
    <p style={{fontSize:17,textAlign:"justify"}}>المدير العام والمؤسّس لشركة قوبر للترجمة  القانونيّة، ليبيّة الجنسيّة، مترجمة محلّفة لثلاث لغات هي الإيطاليّة و الإنجليزيّة والعربيّة. تحمل شهادة في العلاقات السياسيّة الدوليّة، خبرة تتعدّى الأربع سنوات في مجال الترجمة الشفويّة والتحريريّة لمجالات متعدّدة .</p>
    </div>
  </div>
  </CardBody>
  </Card>
</Col>


<Col sm="6"  md='3'>  
 <Card className="mt-4" >
   <CardHeader className="m-0 p-0" style={{backgroundColor:'#FFFF'}}>
     <div style={{marginRight:'25%',width:'41%'}}>
  <img
    alt="..."
    className="img-responsive ml-auto mr-auto"
    src={require("assets/img/jodi.jpeg")}
    style={{borderRadius:"50%"}}
  />
  </div>
  </CardHeader>
  <CardBody className="m-0 p-0">
  <div className="img-details">
    <div className="m-3">
    <h3 className="pt-2 pb-2" style={{color:"#0295E9",fontWeight:'bolder'}}>جودي طوق</h3>
    <p style={{fontSize:17,textAlign:"justify"}}> لبنانيّة الجنسيّة، هي مترجمة محلّفة لأربع لغات وهي الفرنسيّة والإنجليزيّة والتركيّة والعربيّة. تحمل شهادة في مجال الترجمة  من جامعة "USEK". خبرة في مجال الترجمة الشفويّة والتحريريّة تتعدّى الخمس سنوات. عملت كمترجمة لدى إحدى قنوات التلفزة المعروفة علي المستويّ المحليّ والدولي .</p>
    </div>
  </div>
  </CardBody>
  </Card>
</Col>
      

      <Col sm="6"  md='3' >  
 <Card className="mt-4" >
   <CardHeader className="m-0 p-0" style={{backgroundColor:'#FFFF'}}>
     <div style={{marginRight:'25%',width:'42%',textAlign:"justify"}}>
  <img
    alt="..."
    className="img-responsive ml-auto mr-auto"
    src={require("assets/img/reqa.jpeg")}
    style={{borderRadius:"50%"}}
  />
  </div>
  </CardHeader>
  <CardBody className="m-0 p-0">
  <div className="img-details">
    <div className="m-3">
    <h3 className="pt-2 pb-2" style={{color:"#0295E9",fontWeight:'bolder'}}> رفقا إبراهيم</h3>
    <p style={{fontSize:17,textAlign:"justify"}}>لبنانيّة الجنسيّة، هي مترجمة تتقن لغاتٍ ثلاث هي العربيّة والإنكليزيّة والفرنسيّة. حلّت في المرتبة الثانية عند نيلها شهادة في اللغات الحيّة والترجمة من جامعة "USEK". خبرة تتعدّى ثلاث سنوات في مجال ترجمة المستندات القانونيّة والطبيّة والأبحاث العلميّة، وغيرها .</p>
    </div>
  </div>
  </CardBody>
  </Card>
</Col>
<Col sm="6"  md='3' >  
       <Card className="mt-4" >
         <CardHeader className="m-0 p-0" style={{backgroundColor:'#FFFF'}}>
           <div style={{marginRight:'21%',width:'50%'}}>
        <img
          alt="..."
          className="img-responsive ml-auto mr-auto"
          src={require("assets/img/jusa.jpg")}
          style={{borderRadius:"50%"}}
        />
        </div>
        </CardHeader>
        <CardBody className="m-0 p-0">
        <div className="img-details">
         
          <div className="m-3">
          <h3 className="pt-2 pb-2" style={{color:"#0295E9",fontWeight:'bolder'}}>جوزيه رزق</h3>
          <p style={{fontSize:17,textAlign:"justify"}}>لبنانيّة الجنسيّة، هي مترجمة محلّفة تحترف أربع لغات هي العربيّة والإنكليزيّة والفرنسيّة والإسبانيّة. تحمل شهادة في اللّغات الحيّة والترجمة، صادرة عن جامعة "USEK". خبرة تتعدّى أحد عشر عامًا في مجال الترجمة التحريريّة والفوريّة، اكتسبتها لعملها مع جهات مختلفة في لبنان والعالم العربيّ وأوروبا والولايات المتحدة الأميركيّة. </p>
          </div>
        </div>
        </CardBody>
        </Card>
      </Col>
      <Col sm="6"  md='3' >  
       <Card className="mt-4" >
         <CardHeader className="m-0 p-0" style={{backgroundColor:'#FFFF'}}>
         <div style={{marginRight:'21%',width:'50%'}}>
        <img
          alt="..."
          className="img-responsive"
          src={require("assets/img/2.jpg")}
          style={{borderRadius:"50%"}}
        />
        </div>
        </CardHeader>
        <CardBody className="m-0 p-0">
        <div className="img-details">
           <div className="m-3">
          <h3 className="pt-2 pb-3" style={{color:"#0295E9",fontWeight:'bolder'}}>سارة قاسم</h3>
          <p style={{fontSize:17,textAlign:"justify"}}>لبنانيّة الجنسيّة، هي مترجمة محلّفة تحمل شهادة في الترجمة من جامعة ETIB بيروت و شهادة تخصّص في اللّغة الفرنسيّة صادرة عن معهد  "Linguaphone"، بيروت. خبرة تتعدّى أربعة أعوام في مجاليّ الترجمة التحريريّة والفوريّة وتتقن لغاتٍ أربعة هي العربيّة والإنكليزيّة والفرنسيّة والإسبانيّة.</p>
          </div>
        </div>
        </CardBody>
        </Card>
      </Col>

<Col sm="6"  md='3'>  
 <Card className="mt-4" >
   <CardHeader className="m-0 p-0" style={{backgroundColor:'#FFFF'}}>
     <div style={{marginRight:'25%',width:'41%'}}>
  <img
    alt="..."
    className="img-responsive ml-auto mr-auto"
    src={require("assets/img/gada.jpeg")}
    style={{borderRadius:"50%"}}
  />
  </div>
  </CardHeader>
  <CardBody className="m-0 p-0">
  <div className="img-details">
    <div className="m-3">
    <h3 className="pt-2 pb-2" style={{color:"#0295E9",fontWeight:'bolder'}}>د. غادة المسلاتي</h3>
    <p style={{fontSize:17,textAlign:"justify"}}>ليبيّة الجنسيّة، مترجمة متخصّصة في الترجمة الطبّية. طبيبة متخصّصة في الطبّ النسائي والتوليد. تحمل بكالوريوس في تخصّص الطبّ والجراحة البشريّة، حيث نالت نسبة 68 بالمئة عند تخرّجها عام 2015. حلّتت في المرتبة الأولى في الزمالة العربيّة لتخصص الطب النسائي والتوليد.</p>
    </div>
  </div>
  </CardBody>
  </Card>
</Col>



<Col sm="6"  md='3' >  
       <Card className="mt-4" >
         <CardHeader className="m-0 p-0" style={{backgroundColor:'#FFFF'}}>
         <div style={{marginRight:'21%',width:'50%'}}>
        <img
          alt="..."
          className="img-responsive"
          src={require("assets/img/1.jpg")}
          style={{borderRadius:'50%'}}
        />
        </div>
        </CardHeader>
        <CardBody className="m-0 p-0">
        <div className="img-details">
          <div className="m-3">
          <h3 className="pt-2 pb-3" style={{color:"#0295E9",fontWeight:'bolder'}}>أميمة إبراهيم</h3>
          <p style={{fontSize:17,textAlign:"justify"}}>ليبيّة وكنديّة الجنسيّة، هي مترجمة محلّفة تحمل شهادة في الترجمة من وإلى اللغة الألمانيّة من جامعة عمّان في الأردن. عملت كمترجمة فوريّة لدى السفارة الألمانيّة في الأردن، ومدقّق لغوي للغتين الألمانيّة والعربيّة لدى المكتب الاتّحادي للشرطة الجنائيّة.</p>
          </div>
        </div>
        </CardBody>
        </Card>
      </Col>

     


      <Col sm="6"  md='3' >  
 <Card className="mt-4" >
   <CardHeader className="m-0 p-0" style={{backgroundColor:'#FFFF'}}>
     <div style={{marginRight:'25%',width:'41%'}}>
  <img
    alt="..."
    className="img-responsive ml-auto mr-auto"
    src={require("assets/img/mt.jpg")}
    style={{borderRadius:"50%"}}
  />
  </div>
  </CardHeader>
  <CardBody className="m-0 p-0">
  <div className="img-details">
    <div className="m-3">
    <h3 className="pt-2 pb-2" style={{color:"#0295E9",fontWeight:'bolder'}}> محمد يني تورك اوغولاري</h3>
    <p style={{fontSize:17,textAlign:"justify"}}>تركيّ الجنسيّة، يحمل شهادة بكالوريوس من جامعة سيرت، وهو يتقن لغتين هما التركيّة والعربيّة. يعمل في المجال منذ أكثر من ثلاث سنوات، وهو متخصّص في ترجمة المستندات القانونيّة والتقنيّة و العسكريّة والأبحاث العلميّة.</p>
    </div>
  </div>
  </CardBody>
  </Card>
</Col>




<Col sm="6"  md='3' >  
 <Card className="mt-4" >
   <CardHeader className="m-0 p-0" style={{backgroundColor:'#FFFF'}}>
     <div style={{marginRight:'25%',width:'37%'}}>
  <img
    alt="..."
    className="img-responsive ml-auto mr-auto"
    src={require("assets/img/abdrahem.jpeg")}
    style={{borderRadius:"50%"}}
  />
  </div>
  </CardHeader>
  <CardBody className="m-0 p-0">
  <div className="img-details">
    <div className="m-3">
    <h3 className="pt-2 pb-2" style={{color:"#0295E9",fontWeight:'bolder'}}>عبد الرحيم الككلي</h3>
    <p style={{fontSize:17,textAlign:"justify"}}>ليبيّ الجنسيّة، يحمل شهادة بكالوريوس من جامعة تورينو، ويتقن لغات ثلاث هي العربيّة والإنكليزيّة والإيطاليّة. متخصّص في الترجمة التحريريّة والشفويّة لمجالات متعدّدة مثل القانونيّة و العسكريّة والاتّصالات، وغيرها.</p>
    </div>
  </div>
  </CardBody>
  </Card>
</Col>




<Col sm="6"  md='3'>  
 <Card className="mt-4" >
   <CardHeader className="m-0 p-0" style={{backgroundColor:'#FFFF'}}>
     <div style={{marginRight:'25%',width:'41%'}}>
  <img
    alt="..."
    className="img-responsive ml-auto mr-auto"
    src={require("assets/img/kres.jpeg")}
    style={{borderRadius:"50%"}}
  />
  </div>
  </CardHeader>
  <CardBody className="m-0 p-0">
  <div className="img-details">
    <div className="m-3">
    <h3 className="pt-2 pb-2" style={{color:"#0295E9",fontWeight:'bolder'}}>كريستينا قوبر</h3>
    <p style={{fontSize:17,textAlign:"justify"}}> كنديّة الجنسيّة، ولدت لأمّ روسيّة وأب لبناني. تحمل شهادة بكالوريوس في الإعلام والترجمة من جامعة عجمان في العلوم والتكنولوجيا في الإمارات العربية المتحدة، و إدراة اتصالات في جامعة يورك في تورنتو، كندا. خبرة في مجال الترجمة التحريريّة والشفويّة لثلاث لغات هي الروسيّة و الإنجليزيّة و العربيّة لمجالات متعدّدة.</p>
    </div>
  </div>
  </CardBody>
  </Card>
</Col>








<Col sm="6"  md='3'>  
 <Card className="mt-4" >
   <CardHeader className="m-0 p-0" style={{backgroundColor:'#FFFF'}}>
     <div style={{marginRight:'25%',width:'41%'}}>
  <img
    alt="..."
    className="img-responsive ml-auto mr-auto"
    src={require("assets/img/aliyasen.jpeg")}
    style={{borderRadius:"50%"}}
  />
  </div>
  </CardHeader>
  <CardBody className="m-0 p-0">
  <div className="img-details">
    <div className="m-3">
    <h3 className="pt-2 pb-2" style={{color:"#0295E9",fontWeight:'bolder'}}>علي ياسين</h3>
    <p style={{fontSize:17,textAlign:"justify"}}> لبنانيّ الجنسيّة، يحمل شهادة في اللغة الروسيّة من معهد  خاركوف، بالإضافة إلى ماجستير في تقويم الأسنان من جامعة خاركوف الطبيّة العالميّة في أوكرانيا. متخصّص في ترجمة المستندات الطبيّة والعلميّة ولديه خبرة في مجال الترجمة الشفويّة والتحريريّة لثلاث لغات هي الروسيّة والإنجليزيّة والعربيّة.</p>
    </div>
  </div>
  </CardBody>
  </Card>
</Col>

<Col sm="6"  md='3'>  
 <Card className="mt-4" >
   <CardHeader className="m-0 p-0" style={{backgroundColor:'#FFFF'}}>
     <div style={{marginRight:'25%',width:'41%'}}>
  <img
    alt="..."
    className="img-responsive ml-auto mr-auto"
    src={require("assets/img/ahmed.jpeg")}
    style={{borderRadius:"50%"}}
  />
  </div>
  </CardHeader>
  <CardBody className="m-0 p-0">
  <div className="img-details">
    <div className="m-3">
    <h3 className="pt-2 pb-2" style={{color:"#0295E9",fontWeight:'bolder'}}>احمد عدنان</h3>
    <p style={{fontSize:17,textAlign:"justify"}}> لبنانيّ الجنسيّة، يحمل شهادة من جامعة باري في ايطاليا في مجال التكنولوجيا والاتصالات. مترجم لأربع لغات هي الإيطاليّة والفرنسيّة والإنجليزيّة والعربيّة. خبرة تتعدّى الأحد عشرعامًا في مجال الترجمة الشفويّة والتحريريّة لمجالات متعدّدة .
</p>
    </div>
  </div>
  </CardBody>
  </Card>
</Col>






<Col sm="6"  md='3'>  
 <Card className="mt-4" >
   <CardHeader className="m-0 p-0" style={{backgroundColor:'#FFFF'}}>
     <div style={{marginRight:'25%',width:'41%'}}>
  <img
    alt="..."
    className="img-responsive ml-auto mr-auto"
    src={require("assets/img/basm.jpeg")}
    style={{borderRadius:"50%"}}
  />
  </div>
  </CardHeader>
  <CardBody className="m-0 p-0">
  <div className="img-details">
    <div className="m-3">
    <h3 className="pt-2 pb-2" style={{color:"#0295E9",fontWeight:'bolder'}}>باسم الدنب</h3>
    <p style={{fontSize:17,textAlign:"justify"}}>لبنانيّ الجنسيّة، يحمل شهادة في مجال الترجمة والتعليم للغة الإسبانيّة من جامعة "Universidad De Cienfuegos". مترجم محلّف لأربع لغات هي الإسبانيّة والبرتغاليّة والإنجليزيّة والعربيّة. خبرة تتعدّى الثمان سنوات في مجال الترجمة الشفويّة والتحريريّة لمجالات متعدّدة .</p>
    </div>
  </div>
  </CardBody>
  </Card>
</Col>


<Col sm="6"  md='3'>  
 <Card className="mt-4" >
   <CardHeader className="m-0 p-0" style={{backgroundColor:'#FFFF'}}>
     <div style={{marginRight:'25%',width:'41%'}}>
  <img
    alt="..."
    className="img-responsive ml-auto mr-auto"
    src={require("assets/img/may.jpeg")}
    style={{borderRadius:"50%"}}
  />
  </div>
  </CardHeader>
  <CardBody className="m-0 p-0">
  <div className="img-details">
    <div className="m-3">
    <h3 className="pt-2 pb-2" style={{color:"#0295E9",fontWeight:'bolder'}}>مي ياسين</h3>
    <p style={{fontSize:17,textAlign:"justify"}}> لبنانيّة الجنسيّة، تحمل شهادة بكالوريوس وماجستير في مجال علم الأحياء من جامعات فرنسيّة. مترجمة لثلاث لغات هي الإنجليزيّة والفرنسيّة والعربيّة. خبرة في مجال الترجمة التحريريّة والشفويّة لمجالات متعدّدة . </p>
    </div>
  </div>
  </CardBody>
  </Card>
</Col>
      </Row>
      </CardBody>
      </Card>);
  } catch (error) {
   
  }
}








AboutUsSection(){
  // try {
    return(
      <Card className="section mt-3" style={{backgroundColor:'#FFFF',transform:'none'}} id="#who">
       
        <CardHeader style={{backgroundColor:'#FFFF',}}>
          <Container>
        <div style={{textAlign:'center'}}>
        <h2 style={{color:"#0295E9",fontWeight:'bold'}} className="pb-2">
        شركة قوبر للترجمة القانونية
        </h2>
        <h1 className="text-center" style={{color:"#0295E9",fontSize:30}}>
            ترجمة الأوراق القانونية مع فرصة الحصول على الأختام من الجهات المعنية، بفريق من ذوي الكفاءات العالية
       </h1>
        </div>
        </Container>
          </CardHeader>
          <CardBody>
           <Row className="m-2">
           <Col md='4' sm="12">
          <Card style={{background:'#FFFF',transform:'none'}}>
          <CardHeader style={{background:'#FFFF'}}>
              <h2 style={{textAlign:'right',color:"#0295E9",fontWeight:"bolder"}}>
              خدماتنا
              </h2>
          </CardHeader>
          <CardBody style={{background:'#FFFF',transform:'none'}}>
         <p style={{textAlign:'right',fontSize:18,fontWeight:"bolder",direction:'rtl'}}>نتيح لعملائنا فرصة إرسال مستنداتهم، بجميع انواعها، القانونيّة والطبيّة وغيرها، بكبسة زرّ واختيار اللغة المطلوبة. كما يمكنهم الحصول على الأختام من الهيئة القضائيّة الوزارة الخارجيّة. سيصلهم عند إرسالهم المستند ردّاً يحتوي على المدّة المستغرقة للترجمةوالتكلفة الماليّة، كما نقدّم لهم خدمات التوصيل المجاني.</p> </CardBody>
          </Card>
          </Col>
          <Col md='4' sm="12">
          <Card style={{background:'#FFFF',transform:'none'}}>
          <CardHeader style={{background:'#FFFF'}}>
              <h2 style={{textAlign:'right',color:"#0295E9",fontWeight:"bolder"}}>
              رؤيتنا
              </h2>
          </CardHeader>
          <CardBody style={{background:'#FFFF'}}>
         <p style={{textAlign:'right',fontSize:18,fontWeight:"bolder",direction:'rtl'}}>نحن نسعى للنموّ المستدام لنكون الشركة الأكثر ثقة على مستوى ليبيا في مجال الترجمة الكتابيّة والشفويّة، ونكون الأكثر مصداقيّة ونزاهة في علاقاتنا تجاه العملاء. كما نسعى للوصول إلى حلّ جميع المشاكل الخاصّة بعمليّة الترجمة قبل تسليم المستندات إلى عملائنا.</p> </CardBody>
          </Card>
          </Col>

          <Col md='4' sm="12">
          <Card style={{background:'#FFFF',transform:'none'}}>
          <CardHeader style={{background:'#FFFF'}}>
          <h2 style={{textAlign:'right',color:"#0295E9",fontWeight:"bolder"}}>
              من نحن؟
              </h2>
          </CardHeader>
          <CardBody style={{background:'#FFFF'}}>
         <p style={{textAlign:'right',fontSize:18,fontWeight:"bolder",direction:'rtl'}}> 
         شركة قوبر للترجمة القانونيّة هي أوّل شركة تقدّم لعملائها فرصة ترجمة أوراقهم القانونيّة عن بعد بعدة لغات بالإضافة إلى الترجمة الشفويّة الفوريّة. لدينا فريق متكامل من مختلف بلدان العالم مثل ليبيا و لبنان و الأردن و إيطاليا و ألمانيا و روسيا. كما نقدّم لعملائنا فرصة الحصول على الأختام من الهيئة القضائيّة و وزارة الخارجيّة.
         </p> </CardBody>
          </Card>
          </Col>
       </Row>   
            </CardBody>
     <CardFooter>

    </CardFooter>
   
      </Card>
      
      );
  // } catch (error) {
    
  // }
}


RenderSectiontionOurServices(){
  // try {
    return(
    <CardBody id='#s'>
    <Row  className="mr-5 m-3" style={{textAlign:'right',direction:'rtl'}}>
      <Col md="6" sm="12">
      <div className="title"style={{textAlign:'right'}}>
          <h2 style={{textAlign:'right',color:"#0295E9",fontWeight:"bolder"}}> بيانات ضرورية عند إرسال المستند</h2>
          </div>
          
      <div className='mt-4 ml-4' >
          <p style={{fontSize:16,fontWeight:'bold'}}> الرجاء كتابة البيانات التالية عند إرسال المستند للترجمة:</p>
      </div>
      <div className='pt-3 pb-3'>
     
      <ul style={{listStyle:'none',fontSize:16}}>
        <li className='pb-3' style={{textAlign:'right',fontWeight:"bold"}}> <i className="fa fa-check-circle" />  اسم الشخص (كما هو مكتوب في جواز السفر باللغتين العربية والإنجليزية ).</li>
        <li className='pb-3' style={{textAlign:'right',fontWeight:"bold"}}> <i className="fa fa-check-circle" />     رقم الهاتف ،ومكان التسليم .</li>        
        <li className='pb-3' style={{textAlign:'right',fontWeight:"bold"}}> <i className="fa fa-check-circle" /> اللغة المراد الترجمة منها وإليها متلا( الانجليزية الي الفرنسية).</li>
        <li className='pb-3' style={{textAlign:'right',fontWeight:"bold"}}><i className="fa fa-check-circle" /> التوضيح في حالة الرغبة في الحصول على الأختام من (الهيئة القضائية / الوزارة الخارجية). </li> 
      </ul>
</div>
      {/* <div className='pt-3 pb-3'>
      <ul style={{listStyle:'none',fontSize:16}}>
        <li className='pb-3' style={{textAlign:'right',color:"#0295E9",fontWeight:"bolder"}}> <i className="fa fa-envelope" /> Examble@gmail.com</li>
        <li className='pb-3' style={{textAlign:'right',color:"#0295E9",fontWeight:"bolder"}}> <i className="fa fa-envelope" /> Examble@gmail.com</li>        
        <li className='pb-3' style={{textAlign:'right',color:"#0295E9",fontWeight:"bolder"}}> <i className="fa fa-phone" /> 0916737257</li>
        <li className='pb-3' style={{textAlign:'right',color:"#0295E9",fontWeight:"bolder"}}><i className="fa fa-phone" /> 0916737257</li>
        <li className='pb-3' style={{textAlign:'right',color:"#0295E9",fontWeight:"bolder"}}><i className="fa fa-phone" /> 0916737257</li>  
      </ul>
         
</div> */}
</Col>


<Col md="6" sm="12">
      <div className=''>
      <div className="title"style={{textAlign:'right'}}>
          <h2 style={{textAlign:'right',color:"#0295E9",fontWeight:"bolder"}}>  للتواصل معنا  </h2>
          </div>
      <ul style={{listStyle:'none',fontSize:18,fontWeight:'bold'}}>
        <li className='pb-3' style={{textAlign:'right',color:"#0295E9",fontWeight:"bolder"}}> <i className="fa fa-envelope" /> طرابلس / ليبيا</li>
        <li className='pb-3' style={{textAlign:'right',color:"#0295E9",fontWeight:"bolder"}}> <i className="fa fa-envelope" /> translation@koubartr.com</li>  
        <li className='pb-3' style={{textAlign:'right',color:"#0295E9",fontWeight:"bolder"}}> <i className="fa fa-envelope" /> Info@koubartr.com  </li>       
        <li className='pb-3' style={{textAlign:'right',color:"#0295E9",fontWeight:"bolder"}}> <i className="fa fa-phone" /> 00218-91-875-77-72 </li>
        <li className='pb-3' style={{textAlign:'right',color:"#0295E9",fontWeight:"bolder"}}> قوبر للترجمة القانونية.</li>
       
        <li className='pb-3' style={{textAlign:'right',color:"#0295E9",fontWeight:"bolder"}}><i className="fa fa-twitter" style={{padding:15}} onClick={()=>{window.location.href="https://twitter.com/narjeskoubartr"}}/>{'          '}<i className="fa fa-facebook-square" style={{padding:15}} onClick={()=>{window.location.href="https://www.facebook.com/Koubar2020"}}/>{'          '}<i className="fa fa-instagram" style={{padding:15}} onClick={()=>{window.location.href="https://www.instagram.com/koubar_tr/"}}     />{'          '}<i className="fa fa-youtube" onClick={()=>{window.location.href="https://www.youtube.com/channel/UCBSJE5HPBau-W7LS3zQhWFw/discussion"}}         style={{padding:15}}/> </li>  

      </ul>
         
</div>
</Col>
      </Row>
      </CardBody>
      );
  // } 
  // catch (error) {
  // }
}





Stepts(){
  // try {
    return(
      <Card className="section mt-3" style={{transform:'none'}}>
    <CardBody >
    <Row  className="mr-3 mt-3" style={{textAlign:'right',direction:'rtl'}}>
      <Col md="6" sm="12">
      <div className="title"style={{textAlign:'right'}}>
          <h2 style={{textAlign:'right',color:"#0295E9",fontWeight:"bold"}}> خطوات الحصول على ترجمتك:</h2>
          </div>
      <div className='pt-3 pb-3'>
      <ul style={{listStyle:'none',fontSize:16}}>
        <li className='pb-3' style={{textAlign:'right',fontWeight:"bold"}}> <i className="fa fa-check-circle" />   قم بإرسال مستنداتك عبر هاتفك الجوال أو جهاز الكمبيوتر على البريد الإلكتروني الآتي translation@koubartr.com</li>
        <li className='pb-3' style={{textAlign:'right',fontWeight:"bold"}}> <i className="fa fa-check-circle" /> قم بكتابة البيانات التالية: <br/>
        <ul style={{listStyle:'none',fontSize:16}}>
        <li className='pb-3' style={{textAlign:'right',fontWeight:"bold"}}>- اسم الشخص المذكور في الأوراق القانونية حسب جواز السفر (الإنجليزية / العربية).</li>
        <li className='pb-3' style={{textAlign:'right',fontWeight:"bold"}}>- رقم الهاتف للتواصل معك، مكان التسليم.</li>        
        <li className='pb-3' style={{textAlign:'right',fontWeight:"bold"}}>- كتابة اللغة المراد الترجمة منها وإليها (مثلا من الإنجليزية إلى العربية).</li>
        <li className='pb-3' style={{textAlign:'right',fontWeight:"bold"}}>-  كتابة التوضيح في حالة الرغبة في الحصول على الأختام من (الهيئة القضائية أو الوزارة الخارجية أو كلاهما). </li> 
      </ul>
        </li>        
        <li className='pb-3' style={{textAlign:'right',fontWeight:"bold"}}> <i className="fa fa-check-circle" /> سيصلك بريد إلكتروني فور إرسالك للمستند يحتوي على المدة المستغرقة للترجمة / التكلفة المالية.</li>
        <li className='pb-3' style={{textAlign:'right',fontWeight:"bold"}}><i className="fa fa-check-circle" /> الرجاء ارسال الموافقة للتأكيد على إستكمال عملية الترجمة. </li> 
        <li className='pb-3' style={{textAlign:'right',fontWeight:"bold"}}><i className="fa fa-check-circle" /> ستصلك مستنداتك مترجمة عبر بريدك الإلكتروني. </li> 
        <li className='pb-3' style={{textAlign:'right',fontWeight:"bold"}}><i className="fa fa-check-circle" /> كما نوفر خدمات التوصل عند الطلب. </li> 
      
      </ul>
</div>
      {/* <div className='pt-3 pb-3'>
      <ul style={{listStyle:'none',fontSize:16}}>
        <li className='pb-3' style={{textAlign:'right',color:"#0295E9",fontWeight:"bolder"}}> <i className="fa fa-envelope" /> Examble@gmail.com</li>
        <li className='pb-3' style={{textAlign:'right',color:"#0295E9",fontWeight:"bolder"}}> <i className="fa fa-envelope" /> Examble@gmail.com</li>        
        <li className='pb-3' style={{textAlign:'right',color:"#0295E9",fontWeight:"bolder"}}> <i className="fa fa-phone" /> 0916737257</li>
        <li className='pb-3' style={{textAlign:'right',color:"#0295E9",fontWeight:"bolder"}}><i className="fa fa-phone" /> 0916737257</li>
        <li className='pb-3' style={{textAlign:'right',color:"#0295E9",fontWeight:"bolder"}}><i className="fa fa-phone" /> 0916737257</li>  
      </ul>
         
</div> */}
</Col>

      <Col md="6" sm="12">
       <div className="m-2">
        <img
          alt="..."
          className="img-rounded img-responsive"
          src={require("assets/img/contctus.png")}
        />
        </div>
      </Col>
      </Row>
      </CardBody>
      </Card>
      );
  // } 
  // catch (error) {
  // }
}


render(){  
  return (
    <>
     {this.mainbussiness()}
     {this.OureTeam()}
    {this.AboutUsSection()}
    {this.Stepts()}
      <Card className="section mt-3" style={{transform:'none'}}>
        {this.RenderSectiontionOurServices()}
      </Card>
         {/* {this.SendUsMessage()} 
         {this.NewsLatter()} */}
    </>
  );
}
}
export default Example;
